import React from "react";
import { IoCart } from "react-icons/io5";
import { FaLuggageCart } from "react-icons/fa";
import { HiDocumentText, HiOutlineTicket } from "react-icons/hi";
import CountUp from "react-countup";
import { Skeleton, Statistic } from "antd";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";

const DashboardStatsCard = ({
  title,
  valueType,
  count,
  countPrefix,
  value,
  valuePrefix,
  valueFormatter,
  percentage,
  percentageOutcome,
  icon,
  loading,
}) => {
  const { isDarkMode } = useDarkMode();

  const getIconComponent = (iconName) => {
    switch (iconName) {
      case "cart":
        return <IoCart className="text-purple-500" size={24} />;
      case "ticket":
        return <HiOutlineTicket className="text-yellow-500" size={22} />;
      case "documentText":
        return <HiDocumentText className="text-blue-500" size={24} />;
      default:
        return null;
    }
  };

  const iconBgClass = (iconName) => {
    switch (iconName) {
      case "cart":
        return isDarkMode ? "!bg-gray-800" : `bg-purple-50`;
      case "ticket":
        return isDarkMode ? "!bg-gray-800" : `bg-yellow-50`;
      case "documentText":
        return isDarkMode ? "!bg-gray-800" : `bg-blue-50`;
      default:
        return "";
    }
  };

  return (
    <div
      className={`min-h-full hover:drop-shadow-sm hover:shadow-sm flex flex-col justify-between px-4 py-3 border ${
        isDarkMode ? "border-gray-600" : "border-gray-200"
      } rounded-md shadow-sm`}
    >
      <Skeleton
        loading={loading}
        paragraph={{ rows: 2, width: "100%", style: { marginTop: 18 } }}
        active
      >
        <div className="flex items-center justify-between">
          <p className="text-base font-semibold text-gray-400">
            {title}{" "}
            {count && (
              <>
                {`->`}
                <CountUp
                  start={0}
                  className="ml-1"
                  end={count || 0}
                  prefix={countPrefix}
                />
              </>
            )}
          </p>
          <p className="text-xs font-semibold text-gray-400">{valueType}</p>
        </div>

        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center gap-3">
            <div
              className={`h-[2.8rem] w-[2.8rem] rounded-full flex items-center justify-center ${iconBgClass(
                icon
              )}`}
            >
              {getIconComponent(icon)}
            </div>

            <Statistic
              value={value || 0}
              valueStyle={{ fontSize: 30 }}
              prefix={valuePrefix}
              className="font-semibold"
              formatter={valueFormatter}
            />
          </div>

          <div className="flex items-center gap-0.5">
            {percentage !== 0 ? (
              <>
                {percentageOutcome === "positive" ? (
                  <FaArrowUpLong className={"text-green-600"} />
                ) : (
                  <FaArrowDownLong className={"text-orange-400"} />
                )}
                <CountUp
                  end={percentage || 0}
                  suffix="%"
                  className="text-gray-300"
                />
              </>
            ) : (
              <p className="text-gray-400">-</p>
            )}
          </div>
        </div>
      </Skeleton>
    </div>
  );
};

export default DashboardStatsCard;
