import { CaretDownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Typography, Tooltip } from "antd";
import React, { useState } from "react";
import { Handle, Position } from "reactflow";
import { useDarkMode } from "../../../Providers/DarkModeContext";
import { MdChecklist, MdOutlineImage } from "react-icons/md";

const { Text, Paragraph } = Typography;

const CustomJobStatusNode = ({
  data,
  setMenu,
  isFlowFinalized,
  currentProduct,
  capacityFilter,
}) => {
  const { isDarkMode } = useDarkMode();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const dropdownMenu = (
    <Menu className="border-[1.5px] border-black/70 !rounded">
      {data?.productName && (
        <Menu.Item key="productName" className="!p-0 !px-2 !py-0.5">
          <div className="flex flex-wrap items-center w-full gap-1 text-sm">
            <p className="text-[11px] font-semibold">
              <span className="pr-1 font-bold">Product:</span>
              {data?.productName.length !== 0
                ? data?.productName.map((name, index) => (
                    <span key={index} className="">
                      {name}
                      {index < data?.productName.length - 1 && (
                        <span>,&nbsp;</span>
                      )}
                    </span>
                  ))
                : "N/A"}
            </p>
          </div>
        </Menu.Item>
      )}
      {data?.parallel && (
        <Menu.Item key="parallel" className="!p-0 !px-2 !py-0.5">
          <div className="flex flex-wrap items-center w-full gap-1 text-sm">
            <p className="text-[11px] font-semibold">
              <span className="pr-1 font-bold">Parallel to:</span>
              {data?.parallel.length !== 0
                ? data?.parallel.map((id, index) => (
                    <span key={id} className="">
                      {id}
                      {index < data?.parallel.length - 1 && (
                        <span>,&nbsp;</span>
                      )}
                    </span>
                  ))
                : "N/A"}
            </p>
          </div>
        </Menu.Item>
      )}

      <Menu.Item className="!p-0 !px-2 !py-0.5">
        <p className="text-[11px] font-semibold">
          Capacity Enabled: {data?.IsProductionLine ? "Yes" : "No"}
        </p>
      </Menu.Item>

      <Menu.Item className="!p-0 !px-2 !py-0.5">
        <p className="text-[11px] font-semibold">
          Attachment Compulsory: {data?.isAttachmentCompulsory ? "Yes" : "No"}
        </p>
      </Menu.Item>
    </Menu>
  );

  const handleVisibleChange = (visible) => {
    if (visible) {
      setMenu(null);
    }
    setDropdownVisible(visible);
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: "#555" }}
        isConnectable={false}
      />

      <Dropdown
        overlay={dropdownMenu}
        className="!z-10"
        placement="bottomLeft"
        trigger="click"
        open={dropdownVisible}
        onOpenChange={handleVisibleChange}
      >
        <div
          onContextMenu={(e) => {
            e.preventDefault();
            return;
          }}
          className="relative cursor-pointer inline-block min-w-[13rem] max-w-[13rem] !z-10"
        >
          <div
            className={`task-node h-[5.3rem] border border-gray-600 ${
              data?.isDisabled?.includes(currentProduct?.code)
                ? isDarkMode
                  ? "!border-gray-600"
                  : "!border-gray-300"
                : ""
            } text-center`}
          >
            <div
              className={`grid grid-cols-3 pl-[6px] h-fit text-[12px] bg-gray-600/70  ${
                data?.isDisabled?.includes(currentProduct?.code)
                  ? isDarkMode
                    ? "!bg-gray-600 opacity-30 !text-gray-400"
                    : "!bg-gray-300"
                  : ""
              }`}
            >
              <Text className={`py-1 col-span-2 text-right px-4`}>
                {data?.jobStatusId}

                <CaretDownOutlined
                  size={10}
                  className="transition duration-150 text-[12px] ml-1"
                />
              </Text>
              <div
                className={`flex items-center align-middle justify-end gap-1.5 pr-2 ${
                  !isDarkMode && "!text-black"
                }`}
              >
                {data?.isAttachmentCompulsory && (
                  <Tooltip title="Attachment Enabled">
                    <MdOutlineImage size={12} />
                  </Tooltip>
                )}

                {data?.isCheckListCompusory && (
                  <Tooltip title="Checklist Enabled">
                    <MdChecklist size={12} />
                  </Tooltip>
                )}
              </div>
            </div>
            <Paragraph
              className={`flex-1 flex items-center justify-center px-[6px] text-center text-[12px] pt-[10px] ${
                data?.isDisabled?.includes(currentProduct?.code)
                  ? `!text-gray-400 ${isDarkMode ? "opacity-30" : ""}`
                  : ""
              }`}
              ellipsis={{ tooltip: true, rows: 2 }}
            >
              {data?.label.trim(" ")}
            </Paragraph>
          </div>

          <Handle
            type="source"
            position={Position.Bottom}
            style={{ background: "#555" }}
            isConnectable={false}
          />
        </div>
      </Dropdown>
    </>
  );
};

export default CustomJobStatusNode;
