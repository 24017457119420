import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Checkbox, Input, Row, Table, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../../Common/CustomButton";
import TotalListEntries from "../../Common/TotalListEntries";
import { AuthContext } from "../../Providers/AuthProvider";
import { useDarkMode } from "../../Providers/DarkModeContext";
import OrderManagementService from "../../services/OrderManagement/OrderManagementService";
import Roles from "../../services/Roles/Roles";
import { getDarkModeColor } from "../../utils/darkModeHelper";
import ManageRoleDrawer from "./ManageRoleDrawer";

const UserRoles = () => {
  const { isDarkMode } = useDarkMode();
  const [isTableDataLoading, setTableDataLoading] = useState(false);
  const [editData, setEditData] = useState([]);
  const [viewsOptions, setViewsOptions] = useState([]);

  const [changeStatus, setChangeStatus] = useState("");
  let { userPrimaryRole } = useContext(AuthContext);
  let isAdmin = userPrimaryRole?.toLowerCase()?.includes("admin");

  const [list, setList] = useState({
    request: {
      pageRequest: {
        currentPage: 1,
        pageSize: 10,
      },
      sortRequest: {
        key: "",
        direction: true,
      },
      filterRequest: {
        name: "",
        roleType: "",
        description: "",
        dashboardCardNames: "",
        permissionAllowed: "",
        permissionDenied: "",
        privilegeIds: [""],
        isExternal: true,
        allowedOrderView: null,
      },
    },
    response: {
      records: [],
      totalRecords: 0,
    },
  });

  const fetchViewsList = async () => {
    try {
      const res = await OrderManagementService.getOrderViewListPublic();

      const filteredOptions = res?.data?.map((i) => ({
        label: i.name,
        value: i.name,
      }));

      setViewsOptions(filteredOptions);
    } catch (error) {
      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  };

  useEffect(() => {
    const { request } = list;
    getUserData(request);

    fetchViewsList();
  }, []);

  const filterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filterKey,
  }) => (
    <div className="p-4">
      <Input
        placeholder="Search"
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        className="block mb-2"
      />
      <Row className="gap-2">
        <CustomButton
          type="primary"
          onClick={() => confirm()}
          size="small"
          className="flex-1"
        >
          Search
        </CustomButton>
        <CustomButton
          className="flex-1"
          onClick={() => {
            setSelectedKeys([]);
            confirm();
            clearFilters && handleResetFilters(filterKey, clearFilters);
          }}
          size="small"
        >
          Reset
        </CustomButton>
      </Row>
    </div>
  );

  const handleFiltersChange = (pagination, filters, sorter, extra) => {
    const { request } = list;

    const { filterRequest, sortRequest } = request;

    const requestFilters = Object.keys(filterRequest).reduce((acc, key) => {
      if (filters[key]) {
        acc[key] = filters[key][0];
      }
      return acc;
    }, filterRequest);

    setList((prev) => ({
      ...prev,
      request: {
        ...prev.request,
        pageRequest: {
          currentPage: pagination.current,
          pageSize: pagination.pageSize,
        },
      },
    }));

    getUserData({
      ...request,
      filterRequest: {
        ...requestFilters,
        allowedOrderView: requestFilters?.allowedOrderView?.toString(),
      },
      sortRequest: { key: sorter.field, direction: sorter.order === "ascend" },
      pageRequest: {
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filterDropdown: (props) =>
        filterDropdown({ ...props, filterKey: "name" }),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",

      //hiding filter for the description part

      // filterDropdown: (props) =>
      //   filterDropdown({ ...props, filterKey: "description" }),
    },

    // {
    //   title: "Role Type",
    //   dataIndex: "roleType",
    //   key: "roleType",
    //   align: "center",
    //   render: (_, record) => {
    //     return <p>{record.role.roleType}</p>;
    //   },
    // },
    // will be implemented later when the dashboard is ready
    // {
    //   title: "Dashboard Card Count",
    //   dataIndex: "dashBoardCount",
    //   key: "dashBoardCount",
    //   align: "center",
    //   render: (_, record) => {
    //     return <p>{record.dashBoardCount}</p>;
    //   },
    // },
    {
      title: "Privilege's Count",
      dataIndex: "privileges",
      key: "privileges",
      align: "center",
      render: (_, record) => {
        return <p>{record ? record?.role?.privileges?.length : "NA"}</p>;
      },
    },
    {
      title: "Allowed views",
      dataIndex: ["role", "allowedOrderView"],
      key: "allowedOrderView",
      align: "center",
      width: 200,
      render: (text) =>
        text && (
          <Tooltip title={text}>
            <p className="truncate w-[200px] px-4">{text}</p>
          </Tooltip>
        ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        filterKey,
      }) => (
        <div className="p-4 w-[18rem]">
          <Checkbox.Group
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            className="flex flex-col gap-1 mb-2"
          >
            {viewsOptions.map((item) => (
              <Checkbox value={item.value}>{item.label}</Checkbox>
            ))}
          </Checkbox.Group>

          <Row className="gap-2">
            <CustomButton
              type="primary"
              onClick={() => confirm()}
              size="small"
              className="flex-1"
            >
              Search
            </CustomButton>

            <CustomButton
              onClick={() => {
                setSelectedKeys([]);
                confirm();
                clearFilters &&
                  handleResetFilters("allowedOrderView", clearFilters);
              }}
              size="small"
              className="flex-1"
            >
              Reset
            </CustomButton>
          </Row>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record) => {
        return (
          <>
            <CustomButton
              onClick={() => {
                setEditData(record);
                setChangeStatus("edit");
              }}
              icon={<EditOutlined />}
              type="primary"
            >
              Edit
            </CustomButton>
          </>
        );
      },
    },
  ];

  const getUserData = async (request) => {
    try {
      setTableDataLoading(true);
      const { data } = await Roles.getUserRoleList(request);

      setList((prev) => {
        setTableDataLoading(false);
        return {
          ...prev,
          response: data,
        };
      });
    } catch (error) {
      setTableDataLoading(false);

      error?.response?.data?.message
        ? message.error(error?.response?.data?.message)
        : message.error("Something went wrong");
    }
  };

  const handleResetFilters = (key, clearFilters) => {
    clearFilters();
    const { request } = list;

    const { filterRequest } = request;

    const newFilterRequest = {
      ...filterRequest,
      [key]: "",
    };

    setList((prev) => {
      return {
        ...prev,
        request: {
          ...prev.request,
          filterRequest: newFilterRequest,
        },
      };
    });

    getUserData({
      ...request,
      filterRequest: newFilterRequest,
    });
  };

  let tableStartingIndex =
    (list.response.currentPage - 1) * list.response.pageSize + 1;

  if (
    list.response.totalRecords <
    list.response.pageSize * list.response.currentPage
  ) {
    tableStartingIndex =
      (list.response.currentPage - 1) * list.response.pageSize + 1;
  }

  let dataSource = list?.response?.records?.map((item) => {
    return {
      ...item,
      name: item?.role?.name,
      description: item?.role?.description,
      priviledgeCount: item?.role?.priviledgeCount || "NA",
    };
  });

  return (
    <div>
      <div className="flex justify-between mb-2">
        <TotalListEntries
          checkNullCondition={list.response.records.length > 0}
          startingIndex={tableStartingIndex}
          totalEntries={list.response.totalRecords}
          currentPage={list.response.currentPage}
          pageSize={list.response.pageSize}
        />

        <div className="">
          {isAdmin && (
            <CustomButton
              style={{ float: "right" }}
              type="primary"
              block
              icon={<PlusOutlined />}
              onClick={() => {
                setChangeStatus("add");
                setEditData(null);
              }}
            >
              Add
            </CustomButton>
          )}
        </div>
      </div>

      <Table
        size="small"
        bordered={"1px solid black"}
        pagination={{
          pageSize: list.response.pageSize,
          current: list.response.currentPage,
          total: list.response.totalRecords,
          showSizeChanger: true,
        }}
        onChange={handleFiltersChange}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: true }}
        tableLayout="fixed"
        loading={isTableDataLoading}
        rowClassName={(record, index) =>
          index % 2 !== 0 ? getDarkModeColor(isDarkMode, "bg-gray-100") : ""
        }
      />

      <ManageRoleDrawer
        changeStatus={changeStatus}
        initialValue={editData}
        onClose={() => {
          setEditData(null);
          setChangeStatus(null);
        }}
        onSuccess={() => {
          const { request } = list;
          getUserData(request);
        }}
        viewsOptions={viewsOptions}
      />
    </div>
  );
};

export default UserRoles;
