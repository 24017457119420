import React, { useState } from "react";
import { Card, Drawer, Input, Spin, Tooltip, Typography, message } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import ProductionServices from "../../services/Production/ProductionServices";
import { formatDate, formatDateMonth } from "../../utils/dateHelper";
import useDebounce from "../../hooks/useDebounce";

function SearchResultDrawer({ isOpen, onClose }) {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResultList, setSearchResultList] = useState([]);

  const fetchSearchResults = async (searchedText) => {
    if (searchedText.length <= 0) {
      return;
    }

    setIsLoading(true);
    try {
      const res = await ProductionServices.getSearchResultsForScheduler(
        searchedText
      );

      setSearchResultList(res?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      message.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went Wrong"
      );
    }
  };

  const handleSearch = useDebounce((value) => {
    if (value.trim().length <= 0) {
      setSearchResultList([]);
      return;
    }
    fetchSearchResults(value);
  }, 500);

  const handleClear = () => {
    setSearchText("");
    setSearchResultList([]);
  };

  return (
    <Drawer
      title="Search Job Details"
      placement="right"
      width={400}
      onClose={() => {
        onClose();
      }}
      open={isOpen}
      destroyOnClose={false}
    >
      <Input
        addonBefore={<SearchOutlined />}
        placeholder="Enter Job number / status name"
        name="searchText"
        size="medium"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          handleSearch(e.target.value);
        }}
        allowClear={{
          clearIcon: (
            <Tooltip trigger="hover" title="Clear search">
              <DeleteOutlined onClick={handleClear} className="text-blue-600" />
            </Tooltip>
          ),
        }}
      />

      <div className="my-6">
        {isLoading ? (
          <div className="flex items-center justify-center w-full">
            <Spin />
          </div>
        ) : searchResultList.length > 0 ? (
          searchResultList?.map((item, index) => (
            <div key={index}>
              <Card className={"m-2"}>
                <div className={"mb-2"}>
                  <strong className="me-2">Product Name :</strong>
                  {item.productName}
                </div>

                <div className={"mb-2"}>
                  <strong className="me-2">Reference Number :</strong>
                  {item.quoteRefNum}
                </div>

                <div className={"mb-2"}>
                  <strong className="me-2">Status Name :</strong>
                  {item.statusID}. {item.statusName}
                </div>

                <div className={"mb-2"}>
                  <strong className="me-2">Expected Completion Date :</strong>
                  {formatDateMonth(item.expectedCompletionTime)}
                </div>
              </Card>
            </div>
          ))
        ) : (
          searchText.length > 0 && (
            <Typography className="flex items-center justify-center w-full">
              No results found!
            </Typography>
          )
        )}
      </div>
    </Drawer>
  );
}
export default SearchResultDrawer;
