import React, { useState } from "react";
import { message, Select, Form, Popconfirm, Popover } from "antd";
import CustomButton from "../../Common/CustomButton";
import ManageUserService from "../../services/User/ManageUserService";

const RemindUsers = ({
  list,
  selectedRowKeys,
  onSuccess,
  isAllUserSelected,
  privileges,
  children,
}) => {
  const [form] = Form.useForm();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isReminderSetForUser = selectedRowKeys?.some(
    (email) =>
      list.response.records.find((record) => record.emailAddress === email)
        ?.loginReminderStatus?.reminderTriggerDays
  );

  const handleSuccess = () => {
    form.resetFields();
    setIsPopoverOpen(false);
    onSuccess();
  };

  const handleCancel = () => {
    form.resetFields();
    setIsPopoverOpen(false);
  };

  const setReminder = async (values) => {
    try {
      if (!selectedRowKeys.length) {
        message.error("Please select atleast one user");
        return;
      }

      if (isAllUserSelected) {
        await ManageUserService.setReminderForAll({
          noOfDays: values.reminderDays,
        });
      } else {
        await ManageUserService.setReminderForUsers({
          emails: selectedRowKeys,
          days: values.reminderDays,
        });
      }
      message.success("Reminder Set");
      handleSuccess();
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const removeReminder = async () => {
    try {
      if (isAllUserSelected) {
        await ManageUserService.removeReminderForAll();
      } else {
        await ManageUserService.setReminderForUsers({
          emails: selectedRowKeys,
          days: 0,
        });
      }
      message.success("Reminder Removed");
      handleSuccess();
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    privileges && (
      <Popover
        title={"Set Reminder"}
        trigger={"click"}
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        content={
          <Form layout="vertical" form={form} onFinish={setReminder}>
            <Form.Item
              name="reminderDays"
              rules={[{ required: true, message: "Select Days" }]}
            >
              <Select
                className="w-32"
                size="medium"
                placeholder="Select Days"
                allowClear={true}
                options={[
                  { label: "3 Days", value: 3 },
                  { label: "5 Days", value: 5 },
                  { label: "10 Days", value: 10 },
                ]}
              />
            </Form.Item>

            <div className="flex items-center justify-end gap-2 pt-2">
              <CustomButton
                type="primary"
                htmlType="submit"
                size="small"
                className="w-1/2"
              >
                Set
              </CustomButton>
              {isReminderSetForUser ? (
                <Popconfirm
                  title="This will remove the reminder"
                  onConfirm={removeReminder}
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                >
                  <CustomButton type="cancel" size="small" className="w-1/2">
                    Remove
                  </CustomButton>
                </Popconfirm>
              ) : (
                <CustomButton
                  onClick={handleCancel}
                  type="cancel"
                  size="small"
                  className="w-1/2"
                >
                  Cancel
                </CustomButton>
              )}
            </div>
          </Form>
        }
        placement="bottomRight"
      >
        {children}
      </Popover>
    )
  );
};

export default RemindUsers;
