import { createContext, useContext, useEffect, useState } from "react";

const DarkModeContext = createContext();

export function DarkModeProvider({ children }) {
  const [isDarkMode, setTheme] = useState(
    () => JSON.parse(localStorage.getItem("darkMode")) || false
  );

  useEffect(() => {
    const root = window.document.documentElement;

    if (!isDarkMode) {
      root.classList.remove("dark");
      return;
    }
    
    root.classList.add("dark");
    document.body.classList.toggle("dark-mode", isDarkMode);
  }, [isDarkMode]);

  const value = {
    isDarkMode,
    toggleDarkMode: () => {
      localStorage.setItem("darkMode", !isDarkMode);
      setTheme(!isDarkMode);
    },
  };

  return (
    <DarkModeContext.Provider value={value}>
      {children}
    </DarkModeContext.Provider>
  );
}

export const useDarkMode = () => {
  const context = useContext(DarkModeContext);

  if (context === undefined)
    throw new Error("useDarkMode must be used within a DarkModeProvider");

  return context;
};
