import { Card, Divider, Table, Tag, Typography } from "antd";
import React from "react";
import { FaPhone } from "react-icons/fa";
import { FaLocationCrosshairs, FaMapLocation } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../Providers/AuthProvider";
import { useDarkMode } from "../../Providers/DarkModeContext";
import { formatDate } from "../../utils/dateHelper";

const PrintReceipt = ({ orderDetails, componenetRef }) => {
  const { isDarkMode } = useDarkMode();
  const { currentURL } = useAuth();

  let priceSource = [];

  if (orderDetails) {
    priceSource = [
      {
        name: "Sub-total",
        price: orderDetails?.subTotal || 0,
      },
      {
        name: "Packaging & Handling Charges",
        price: orderDetails?.handlingCharges || 0,
      },
      { name: "GST", price: orderDetails?.tax || 0 },
      {
        name: "Order Total",
        price: orderDetails?.total || 0,
      },
    ];
  }

  const priceColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "90%",
      render: (text) => <span className="font-semibold">{text}</span>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: (text) => {
        return (
          <NumericFormat
            prefix={"$"}
            value={text}
            className="font-semibold"
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
        );
      },
    },
  ];

  const parsedAttachmentsDetails = orderDetails?.accessoriesInfo
    ? JSON.parse(orderDetails?.accessoriesInfo)
    : [];

  const attributesInfo = orderDetails?.attributesInfo
    ? JSON.parse(orderDetails?.attributesInfo)
    : [];

  const productPrice =
    parseInt(orderDetails?.unitPrice) * (parseInt(orderDetails?.quanity) || 1);

  return (
    <Card
      type="inner"
      ref={componenetRef}
      title={
        <div className="py-3">
          <Typography className="text-lg font-bold">
            {orderDetails?.jobStatusDescription}
          </Typography>

          <div className="flex items-center gap-5 mt-1">
            <Typography>
              <span className="font-semibold">Number:</span>{" "}
              {orderDetails?.orderItem?.ordNum}
            </Typography>

            {orderDetails?.ordDate && (
              <Typography>
                <span className="font-semibold">Date:</span>{" "}
                {formatDate(orderDetails?.ordDate)}
              </Typography>
            )}
          </div>
        </div>
      }
      extra={
        orderDetails?.enteredByUser?.name && (
          <Tag color="orange">{orderDetails?.enteredByUser?.name}</Tag>
        )
      }
    >
      <Typography className="text-base font-semibold">
        Customer Details:
      </Typography>

      <div className="flex flex-col gap-1 mt-1">
        <Typography className="flex items-center gap-1 text-sm">
          Customer:
          <Typography className="font-medium">{`${orderDetails?.accountID} | ${orderDetails?.company}`}</Typography>
        </Typography>

        <div className="flex items-center gap-5">
          {orderDetails?.contact?.name && (
            <Typography className="flex items-center gap-1 text-sm">
              Contact Name:
              <Typography className="font-medium">
                {orderDetails?.contact?.name}
              </Typography>
            </Typography>
          )}

          {orderDetails?.contact?.email && (
            <Typography className="flex items-center gap-1 text-sm">
              Email:
              <Typography className="font-medium">
                {orderDetails?.contact?.email}
              </Typography>
            </Typography>
          )}

          {orderDetails?.contact?.mobile && (
            <Typography className="flex items-center gap-1 text-sm">
              Phone:
              <Typography className="font-medium">
                {orderDetails?.contact?.mobile}
              </Typography>
            </Typography>
          )}
        </div>

        {(attributesInfo?.[0]?.endCustomerName ||
          attributesInfo?.[0]?.vehicleChasisNo) && (
          <div className="flex items-center gap-5">
            {attributesInfo?.[0]?.endCustomerName && (
              <Typography className="flex items-center gap-1 text-sm">
                End Customer's Name:
                <Typography className="font-medium">
                  {attributesInfo?.[0]?.endCustomerName}
                </Typography>
              </Typography>
            )}

            {attributesInfo?.[0]?.vehicleChasisNo && (
              <Typography className="flex items-center gap-1 text-sm">
                Vehicle Chassis No:
                <Typography className="font-medium">
                  {attributesInfo?.[0]?.vehicleChasisNo}
                </Typography>
              </Typography>
            )}
          </div>
        )}

        {(attributesInfo?.[0]?.fittingOption1 ||
          attributesInfo?.[0]?.fittingOption2) && (
          <div className="flex items-center gap-5">
            <Typography className="flex items-center gap-1 text-sm">
              Fittings:
              {attributesInfo?.[0]?.fittingOption1 && (
                <Typography className="font-medium">
                  {attributesInfo?.[0]?.fittingOption1},
                </Typography>
              )}
              {attributesInfo?.[0]?.fittingOption2 && (
                <Typography className="ml-1 font-medium">
                  {attributesInfo?.[0]?.fittingOption2}
                </Typography>
              )}
            </Typography>
          </div>
        )}

        <Divider className="my-2 border-gray-400 border-opacity-60" />

        {/* Address Details */}
        <div className="grid grid-cols-2 divide-x place-items-start justify-items-start">
          <div>
            <Typography className="flex items-center gap-1 text-base font-semibold">
              Billing Address
            </Typography>

            <div className="flex flex-col gap-2 mt-2">
              {orderDetails?.billingAddress?.phone && (
                <Typography className="flex items-center gap-1 text-sm">
                  <FaPhone />
                  <Typography>{orderDetails?.billingAddress?.phone}</Typography>
                </Typography>
              )}

              <Typography className="flex items-center gap-1 text-sm">
                <IoIosMail size={20} />
                <Typography>
                  {orderDetails?.billingAddress?.emailAddress}
                </Typography>
              </Typography>

              <Typography className="flex items-center gap-1 text-sm">
                <FaLocationCrosshairs size={17} />
                <Typography>
                  {orderDetails?.billingAddress?.country},{" "}
                  {orderDetails?.billingAddress?.state},{" "}
                  {orderDetails?.billingAddress?.city},{" "}
                  {orderDetails?.billingAddress?.postCode}
                </Typography>
              </Typography>

              <Typography className="flex items-center gap-1 text-sm">
                <FaMapLocation size={17} />
                <Typography>
                  {orderDetails?.billingAddress?.addressLine1},
                  {orderDetails?.billingAddress?.addressLine2}
                </Typography>
              </Typography>
            </div>
          </div>

          <div className="flex flex-col items-start gap-1 pl-5 text-sm">
            <Typography className="flex items-center gap-1 text-base font-semibold">
              Shipping Address
            </Typography>

            <div className="flex flex-col gap-2 mt-2">
              {orderDetails?.shippngAddress?.phone && (
                <Typography className="flex items-center gap-1 text-sm">
                  <FaPhone />
                  <Typography>{orderDetails?.shippngAddress?.phone}</Typography>
                </Typography>
              )}

              <Typography className="flex items-center gap-1 text-sm">
                <IoIosMail size={20} />
                <Typography>
                  {orderDetails?.shippngAddress?.emailAddress}
                </Typography>
              </Typography>

              <Typography className="flex items-center gap-1 text-sm">
                <FaLocationCrosshairs size={17} />
                <Typography>
                  {orderDetails?.shippngAddress?.country},{" "}
                  {orderDetails?.shippngAddress?.state},{" "}
                  {orderDetails?.shippngAddress?.city},{" "}
                  {orderDetails?.shippngAddress?.postCode}
                </Typography>
              </Typography>

              <Typography className="flex items-center gap-1 text-sm">
                <FaMapLocation size={17} />
                <Typography>
                  {orderDetails?.shippngAddress?.addressLine1},
                  {orderDetails?.shippngAddress?.addressLine2}
                </Typography>
              </Typography>
            </div>
          </div>
        </div>

        <Divider className="my-2 border-gray-400 border-opacity-60" />

        {/* Product Details */}
        <div className="flex items-start justify-between w-full">
          <div>
            <Typography className="flex items-center gap-1 text-base font-semibold">
              Product Details
              <span className="ml-1 text-sm italic">
                ( Quantity: {orderDetails?.quanity ? orderDetails?.quanity : 1}{" "}
                )
              </span>
            </Typography>

            {!currentURL.includes("aj") ? (
              <Typography className="flex items-center gap-1 text-sm">
                Product Name:
                <Typography className="font-medium">
                  {orderDetails?.orderItem?.product?.name}
                </Typography>
              </Typography>
            ) : (
              <div className="flex flex-col gap-1 mt-2">
                <Typography className="flex items-center gap-1 text-sm">
                  Type of Bar:
                  <Typography className="font-medium">
                    {orderDetails?.orderItem?.product?.name}
                  </Typography>
                </Typography>

                <Typography className="flex items-center gap-1 text-sm">
                  Make:
                  <Typography className="font-medium">
                    {orderDetails?.productInfo?.[0]?.make}
                  </Typography>
                </Typography>

                <Typography className="flex items-center gap-1 text-sm">
                  Model:
                  <Typography className="font-medium">
                    {orderDetails?.productInfo?.[0]?.model}
                  </Typography>
                </Typography>

                <Typography className="flex items-center gap-1 text-sm">
                  Bar Finish:
                  <Typography className="font-medium">
                    {orderDetails?.productInfo?.[0]?.customBarFinish !== "null"
                      ? orderDetails?.productInfo?.[0]?.customBarFinish
                      : orderDetails?.productInfo?.[0]?.barFinish}
                  </Typography>
                </Typography>

                <Typography className="flex items-center gap-1 text-sm">
                  Number Plate:
                  <Typography className="font-medium">
                    {orderDetails?.productInfo?.[0]?.numberPlate}
                  </Typography>
                </Typography>

                <Typography className="flex items-center gap-1 text-sm">
                  Blacking Plate Colour:
                  <Typography className="font-medium">
                    {orderDetails?.productInfo?.[0]
                      ?.customblackingPlateColor !== "null"
                      ? orderDetails?.productInfo?.[0]?.customblackingPlateColor
                      : orderDetails?.productInfo?.[0]?.blackingPlate}
                  </Typography>
                </Typography>

                <Typography className="flex items-center gap-1 text-sm">
                  Indicators:
                  <Typography className="font-medium">
                    {orderDetails?.productInfo?.[0]?.customIndicators !== "null"
                      ? orderDetails?.productInfo?.[0]?.customIndicators
                      : orderDetails?.productInfo?.[0]?.indicators}
                  </Typography>
                </Typography>

                <Typography className="flex items-center gap-1 text-sm">
                  Integrated Lights:
                  <Typography className="font-medium">
                    {orderDetails?.productInfo?.[0]?.make}
                  </Typography>
                </Typography>
              </div>
            )}
          </div>

          <div className="flex flex-col items-end justify-end">
            <Typography className="flex items-center gap-1 text-base font-semibold">
              Cost
            </Typography>

            <NumericFormat
              prefix={"$"}
              value={productPrice}
              displayType={"text"}
              className="text-base font-bold"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={true}
            />
          </div>
        </div>

        {parsedAttachmentsDetails?.length > 1 && (
          <div className="flex items-start justify-between w-full mt-3">
            <div className="w-full">
              <Typography className="flex items-center gap-1 text-base font-semibold">
                Accessories
              </Typography>

              {parsedAttachmentsDetails.map(
                (i, index) =>
                  index > 0 && (
                    <div
                      key={index}
                      className="flex flex-col w-full gap-1 mt-2"
                    >
                      <Typography className="flex items-center justify-between w-full gap-1 text-sm">
                        <Typography>
                          {index}. {i?.accessories}
                        </Typography>

                        <Typography className="text-base font-semibold">
                          <NumericFormat
                            prefix={"$"}
                            value={parseInt(i?.price) || 0}
                            displayType={"text"}
                            className="text-base font-bold"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                          />
                        </Typography>
                      </Typography>

                      <Typography className="flex items-center gap-1 text-sm">
                        Description:
                        <Typography className="font-medium">
                          {i?.description}
                        </Typography>
                      </Typography>

                      <Typography className="flex items-center gap-1 text-sm">
                        Number:
                        <Typography className="font-medium">
                          {i?.numberOfLights}
                        </Typography>
                      </Typography>

                      <Typography className="flex items-center gap-1 text-sm">
                        Layout:
                        <Typography className="font-medium">
                          {i?.lightLayout}
                        </Typography>
                      </Typography>

                      <Typography className="flex items-center gap-1 text-sm">
                        Mounting:
                        <Typography className="font-medium">
                          {i?.lightMounting}
                        </Typography>
                      </Typography>

                      {index + 1 !== parsedAttachmentsDetails?.length && (
                        <Divider className="my-1" />
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        )}

        <Divider className="my-2 border-gray-400 border-opacity-60" />

        {/* Order Summary */}
        <div>
          <Typography className="flex items-center gap-1 mb-2 text-base font-semibold">
            Order Summary
          </Typography>

          <Table
            columns={priceColumns}
            dataSource={priceSource}
            pagination={false}
            showHeader={false}
            bordered
            rowClassName={(_, index) =>
              index === 3 ? (isDarkMode ? "bg-gray-800" : "bg-gray-200") : ""
            }
          />
        </div>
      </div>
    </Card>
  );
};

export default PrintReceipt;
