import axios from "../axios";

class DashboardServices {
  static Instance() {
    return new DashboardServices();
  }

  getDashboardCardDetailsCount(noOfDays) {
    return axios.get(`/api/dashboard/get/Dashboard?NoOfDays=${noOfDays}`);
  }

  getProductGroups() {
    return axios.get("/api/product/group/list");
  }

  getAllChartsData(noOfDays, productGroupId) {
    return axios.get(
      `/api/dashboard/all/charts?NoOfDays=${noOfDays}&ProductGroupId=${productGroupId}`
    );
  }

  getDashboardAnalyticsData(noOfDays) {
    return axios.get(`/api/dashboard/analytics?NoOfDays=${noOfDays}`);
  }

  getProductionLineData(noOfDays, productGroup) {
    return axios.get(
      `/api/dashboard/production/charts?NoOfDays=${noOfDays}${
        productGroup ? `&ProductGroupID=${productGroup}` : ""
      }`
    );
  }

  getCapacityData(noOfDays, productGroup) {
    return axios.get(
      `/api/dashboard/capacity/chart?NoOfDays=${noOfDays}${
        productGroup ? `&ProductGroupID=${productGroup}` : ""
      }`
    );
  }

  getCancelledReasonChartData() {
    return axios.get(`/api/dashboard/cancelled/orders`);
  }

  getForecastingChartData(days) {
    return axios.get(`/api/dashboard/forecast?NoOfDays=${days}`);
  }
}
export default DashboardServices.Instance();
